import * as React from "react"
import Layout from "../components/layout"
import ButtonRdv from "../components/buttonRdv"
import digidombesWhite from "../images/digidombes-white.png"
import mask from "../images/mask-digidombes.png"

// markup
const IndexPage = () => {
  return (
    <Layout>
      <header className="row">
        <div className="col text-center">
          <img className="item my-5" src={digidombesWhite}/>
        </div>
      </header>
      <section className="row">
        <div className="col-md-6">
          <img className="img-fluid mask" src={mask}/>
        </div>

        <div className="col-md-5 offset-md-1" >
          <h1>Création de<br/>
            Site internet et logo
          </h1>
          <p className="txt-grey">digiDombes vous accompagne dans la communication avec vos clients, vos partenaires et vos collaborateurs.<br/>
            <span className="green">Vous êtes entre de bonnes mains !</span></p>
          <div className="mt-5">
            <a class="btn btn-green mb-4" href="creation-site-web-et-logo">NOS TARIFS SITE INTERNET ET LOGO</a>
            <ButtonRdv/>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
